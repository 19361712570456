.user {
  position: relative;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 10px;
  background-color: var(--greyD9);
  transition: .3s;
}

.user:hover {
  z-index: 20;
  transform: scale(1.1);
}

.initial {
  font-size: 12px;
}
