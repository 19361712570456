.previewFiles {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  margin-top: 10px;
}

.photoVideo {
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.files {
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.previewItem {
  gap: 10px;
}

.previewImage {
  position: relative;
  width: 70px;
  height: 70px;
}

.previewImageAudio {
  width: 20px;
  height: 20px;
}

.previewImageFile {
  width: 40px;
  height: 40px;
}

.previewImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.previewImage video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.videoInit {
  position: absolute;
  bottom: 2px;
  left: 2px;
  color: red;
  background-color: #000;
}

.previewImage svg {
  width: 100%;
  height: 100%;
}

.delbtn {
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: var(--white);
  border-radius: 3px;
}
