.uploadWrapper {
  position: relative;
}

.count {
  position: absolute;
  font-size: 10px;
  top: -10px;
  right: -4px;
  padding: 2px;
  border-radius: 3px;
  background-color: var(--blueECF);
}

.upload {
  width: 16px;
  height: 16px;
}

.upload svg {
  width: 100%;
  height: 100%;
}
