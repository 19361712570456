.cardTags {
  width: 100%;
  align-items: flex-start;
}

.btn {
  margin-top: 3px;
}


@media (max-width: 1400px) {}

@media (max-width: 1200px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {
  .cardTags {
    order: -1;
    padding-right: 40px;
  }
}

@media (max-width: 576px) {}
