.confirmPopupWindow {
  flex-direction: column;
  min-width: max-content;
  align-items: flex-start;
  z-index: 5000;
}
.deleteHeader {
  font-size: 16px;
  margin-bottom: 20px;
}
.deleteBtn {
  margin-right: 16px;
}
.confirmPopupWindow button {
  padding: 8px 25px;
}
