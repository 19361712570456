.loginTop {
  width: 100%;
  height: 98px;
}

.loginPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url('../../assets/images/LoginBack.jpg');
  background-size: cover;
  background-position: top center;
}

.linksBox {
  gap: 22px;
}

.lang {
  width: 37px;
  height: 37px;
  border: 1px solid var(--white);
  border-radius: 50%;
  justify-content: center;
}

/* .banner {
  flex-direction: column;
  justify-content: center;
}

.mainTitle {
  color: var(--white);
  font-family: 'Lexend';
  font-size: 120px;
  font-weight: 800;
  line-height: 150px;
  letter-spacing: 0.12em;
}

.mainDescr {
  padding: 10px 50px;
  border-radius: 30px;
  background-color: #f6f6f6;
  color: #2B313F;
  font-family: 'Raleway';
  font-size: 32px;
  font-weight: 300;
  line-height: 37.57px;
} */

.formContainer {
  justify-content: center;
  flex-grow: 1;
  padding: 15px;
}

.formWrapper {
  position: relative;
  gap: 20px;
  width: 1180px;
  padding: 70px 90px;
  border-radius: 10px;
  background-color: var(--whiteF4);
  box-shadow: -11px 11px 22px 0px #DBDBDB33;
}

.errorForm {
  position: absolute;
  left: 0;
  top: -30px;
  color: var(--redCF);
}

.formName {
  width: 100%;
  margin-bottom: 40px;
  color: var(--blue212);
  font-family: Raleway;
  font-size: 28px;
  font-weight: 700;
  white-space: nowrap;
}

.form {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 28px;
}

.inputWrapper {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.label {
  font-family: 'Raleway';
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
}

.inputBox {
  position: relative;
}

.inputBox.boxError::after {
  content: '!';
  position: absolute;
  right: 50px;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: var(--redCF);
  border-radius: 50%;
  border: 1px solid var(--redCF);
}

.input {
  width: 340px;
  height: 50px;
  padding: 0 80px 0 20px;
  border-radius: 25px;
  border: 1px solid var(--grey78);
  box-shadow: 5px 5px 13px 0px #DDDDDDE5 inset,
    -5px -5px 10px 0px #FFFFFFE5 inset,
    5px -5px 10px 0px #DDDDDD33 inset,
    -5px 5px 10px 0px #DDDDDD33 inset,
    -1px -1px 2px 0px #DDDDDD80,
    1px 1px 2px 0px #FFFFFF4D;
  background: #F3F3F3;
  color: var(--black05);
  font-size: 14px;
}

.input::placeholder {
  color: #A2A5AA;
}

.input:focus {
  border: 2px solid #35BBFF;
  outline: unset;
}

.input.error {
  border: 1px solid var(--redCF);
}

.passBtn {
  position: absolute;
  right: 20px;
  top: 15px;
  width: 20px;
  height: 20px;
  border: unset;
  background: transparent;
}

.errorMessage {
  position: absolute;
  bottom: -16px;
  left: 10px;
  color: var(--redCF);
  font-size: 12px;
}

.formBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
  color: var(--white);
  font-family: 'Raleway';
  background: linear-gradient(92.09deg, #0452F0 5.19%, #2128BB 93.82%);
  transition: .3s;
  cursor: pointer;
}

.formBtn:hover {
  background: linear-gradient(92.09deg, #2128BB 5.19%, #0452F0 93.82%);
}

.formBtn:active {
  background: linear-gradient(92.09deg, #0452F0 5.19%, #2128BB 93.82%);
}

.btnSubmit {
  width: 260px;
  height: 50px;
  border-radius: 25px;
  font-size: 22px;
  font-weight: 700;
}

.btnSubmit:disabled {
  background: var(--grey78);
  cursor: auto;
}

.banner {
  min-width: 500px;
  width: 500px;
  min-height: 360px;
  padding: 40px 50px;
  border-radius: 20px;
  background: linear-gradient(87.65deg, #0049FF 0.49%, #35BBFF 48.27%);
  box-shadow: -11px 11px 22px 0px #DBDBDB33,
    -1px -1px 2px 0px #DBDBDB80 inset,
    1px 1px 2px 0px #FFFFFF4D inset;
}

.banner * {
  color: white;
  font-family: 'Raleway';
}

.bannerTitle {
  margin-bottom: 15px;
  font-size: 45px;
  font-weight: 700;
}

.bannerText {
  font-size: 23px;
  font-weight: 400;
}

.offerBtn {
  margin-top: 40px;
  padding: 12px 30px;
  border-radius: 25px;
  font-size: 18px;
}

@media (max-width: 1200px) {
  .formWrapper {
    gap: 20px;
    padding: 30px 40px;
  }
}

@media (max-width: 1024px) {
  .formWrapper {
    flex-direction: column;
    width: max-content;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 992px) {
  .banner {
    display: none;
  }
}


@media (max-width: 576px) {
  .formWrapper {
    width: 100%;
    align-items: center;
  }

  .formName {
    margin-bottom: 30px;
    font-size: 20px;
    white-space: wrap;
  }

  .form {
    gap: 20px;
  }

  .inputWrapper {
    gap: 10px;
  }

  .input {
    width: 100%;
  }

  .label {
    font-size: 16px;
  }

  .btnSubmit {
    min-width: 100%;
    height: 40px;
    font-size: 18px;
  }
}
