.taskSidebar {
  flex-direction: column;
  align-items: flex-start;
}

.sidebarBtn {
  margin-bottom: 30px;
}

.fileLabel {
  gap: 10px;
}
