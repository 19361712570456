.menu {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--white);
  z-index: 65;
}
