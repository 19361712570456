.right {
  align-self: flex-end;
}

.left {
  align-self: flex-start;
}

.system span {
  display: block;
  font-size: 12px;
  color: var(--grey78);
  text-align: center;
  padding: 5px 20px;
}

.messageBlock {
  width: 100%;
  gap: 10px;
  align-items: flex-end;
}

.avatar {
  justify-content: center;
  width: 35px;
  min-width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: green;
}

.message {
  position: relative;
  max-width: 100%;
  min-width: 100px;
  background: rgba(62, 81, 150, .8);
  border-radius: 10px;
  padding: 5px 10px 15px 10px;
}

.myMessage {
  flex-direction: row-reverse;
}

.myMessage .message {
  background-color: rgba(62, 81, 150, .2);
}

.support {
  background: rgba(62, 81, 150, .5);
}

.time {
  position: absolute;
  font-size: 8px;
  bottom: 2px;
  left: 10px;
  right: auto;
  color: var(--white);
}

.myMessage .time {
  right: 10px;
  left: auto;
  color: var(--grey78);
}
