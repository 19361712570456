.filesWrap {
  width: 100%;
  margin-bottom: 15px;
}

.filesTitleWrap {
  margin-bottom: 12px;
}

.emptyFiles {
  margin-bottom: 20px;
}
