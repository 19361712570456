.botRad {
  border-radius: 2px 2px 0 0;
}

.cardTag {
  position: relative;
}

.tagControls {
  position: absolute;
  top: 100%;
  left: 0;
  gap: 5px;
  justify-content: flex-start;
  padding: 0 5px 3px 5px;
  width: 100%;
  border-radius: 0 0 2px 2px;
  z-index: 20;
}

.tagBtn {
  width: 15px;
  height: 15px;
}
