.controls {
  position: absolute;
  z-index: 50;
}

.controlsComment {
  right: 0;
  top: 16px;
  background-color: var(--white);
}

.controlsRow {
  top: 50%;
  flex-direction: column;
  padding: 10px 5px;
  border-radius: 5px;
  border: 1px solid var(--blueECF);
  transform: translateY(-50%);
}

@media (max-width: 1400px) {}

@media (max-width: 1200px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {
 .relative {
  position: static;
 }
}

@media (max-width: 600px) {

}
