.fullchat {
  position: fixed;
  bottom: 20px;
  right: 20px;
  flex-direction: column;
  width: 420px;
  max-height: 600px;
  height: calc(100vh - 40px);
  z-index: 950;
  overflow: hidden;
  padding: 0;
}

.header {
  width: 100%;
  min-height: 50px;
  height: 50px;
  padding: 0 20px;
  background-color: var(--blue3E5);
}

.title {
  color: var(--white);
  font-size: 16px;
}

.body {
  flex-direction: column;
  gap: 5px;
  justify-content: flex-start;
  height: 100%;
  padding: 10px;
  overflow-y: auto;
}

.footer {
  width: 100%;
  height: 130px;
  min-height: 130px;
  padding: 10px;
  background-color: var(--blue3E5);
}

.form {
  height: 100%;
  gap: 10px;
}

.inputWrapper {
  height: 100%;
}

.input {
  height: 100%;
  background-color: rgba(255, 255, 255, .8);
}

.sendBtn {
  background-color: var(--white);
  border-radius: 50%;
  padding: 5px;
}

.loader {
  height: 14px;
}

.loader img {
  height: 100%;
}

@media (max-width: 500px) {
  .fullchat {
    right: 10px;
    width: calc(100% - 20px);
  }
}
