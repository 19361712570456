.typesWrapper {
  position: absolute;
  right: 50%;
  top: calc(100% + 10px);
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;

  padding: 15px;
  border-radius: 10px;
  background-color: var(--white);
  transform: translateX(50%);
  z-index: 500;
}

.typeItem {
  width: 100%;
  transition: .3s;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
}

.typeItem:hover {
  color: var(--blue3E5);
}
