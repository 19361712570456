.filesWindow {
  min-width: max-content;
  flex-direction: column;
}

.filesWrapper {
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 345px;
  height: 166px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid var(--greyE9);
  transition: .2s;
}

.filesWrapper.activeWrapper {
  background: var(--blueECF);
}

.fileLabel {
  position: absolute;
  inset: 0;
  cursor: pointer;
}

.confirmBtn {
  align-self: flex-end;
}

.filesList {
  width: 100%;
  margin-bottom: 20px;
}

.fileItem {
  width: 100%;
  border-bottom: 1px solid var(--greyE9);
}

.fileItem:not(:last-child) {
  margin-bottom: 10px;
}

.linksWrapp {
  width: 100%;
}

.addLink {
  padding: 5px 10px;
  margin-bottom: 10px;
  font-size: 14px;
}

.typeTitle {
  align-self: flex-start;
  margin-bottom: 10px;
}

@media (max-width: 1400px) {}

@media (max-width: 1200px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {}

@media (max-width: 420px) {
  .filesWindow {
    min-width: 100%;
  }

  .filesWrapper {
    width: 100%;
  }
}
