.taskFooter {
  width: 100%;
}

.taskMeta, .taskAdditionals {
  gap: 13px;
  width: max-content;
}

.taskFooterItem {
  gap: 5px;
  color: var(--blue3E5);
  font-size: 10px;
}
