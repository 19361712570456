.list {
  position: fixed;
  right: 10px;
  bottom: 180px;
  width: 563px;
  padding: 10px 8px;
  z-index: 500;
}

@media (max-width: 1400px) {}

@media (max-width: 1200px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {
  .list {
    width: 540px;
  }
}

@media (max-width: 576px) {
  .list {
    width: 300px;
  }
}
