.checkListBlock {
  width: 100%;
}

.checkTitleWrap {
  margin-bottom: 12px;
}

.indicatorWrapper {
  margin-bottom: 10px;
}

.percent {
  margin-right: 8px;
  color: var(--blue3E5);
  font-weight: 500;
}

.indicatorFull {
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  overflow: hidden;
  background-color: var(--greyF4);
}

.indicatorPercent {
  position: absolute;
  left: 0;
  height: 100%;
  border-radius: 2px;
  background-color: var(--blue3E5);
  transition: .3s;
}
