.controls {
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--white);
  z-index: 50;
  width: min-content;
}

.columnControls {
  top: calc(100% + 10px);
  transform: none;
}

.prefColor {
  width: 100%;
  padding-top: 10px;
  border-top: 1px solid var(--greyF4);
}

.colorsMenu {
  justify-content: flex-start;
  max-width: 100%;
  margin-top: 10px;
}

.colorsWrapper {
  justify-content: flex-start;
  gap: 6px;
  flex-wrap: wrap;
  margin-right: 10px;
  max-width: 100%;
}

.colorInputBox {
  position: relative;
  min-width: 23px;
  height: 23px;
}

.colorInput {
  visibility: hidden;
  position: absolute;
}

.colorLabel {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  cursor: pointer;
}

.colorLabel::after {
  content: '';
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.colorInputBox .colorInput:checked+.colorLabel::after {
  content: url("../../../../../../assets/images/icons/checked.svg");
}

/* .colorAddBtn {
  margin-top: 5px;
  align-self: flex-start;
} */

.myColor {
  flex-direction: column;
  gap: 10px;
}

.createNewWrap {
  width: 100%;
  margin-bottom: 20px;
}

.createNewColor {
  width: 100%;
}

.colorAddBtn {
  position: absolute;

  opacity: 0;
}

.colorInputWrapper {
  width: max-content;
  height: max-content;
  min-width: 1px;
}

.colorFromInput {
  border: 1px solid var(--blue3E5);
  border-radius: 4px;
  min-width: 18px;
  height: 18px;
}

.resizeSVG svg {
  width: 18px;
}
