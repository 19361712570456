.list {
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  position: absolute;
  left: 0;
  top: calc(100% + 5px);
  padding: 15px;
  border-radius: 10px;
  background-color: var(--white);
  z-index: 5;
  width: max-content;
}
