.checkWrap {
  max-width: 385px;
}

.createNewWrap {
  width: 100%;
  margin-bottom: 20px;
}

.createNewColor {
  width: 100%;
}
