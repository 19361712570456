.filesWrapper {
  margin-top: 20px;
}

.files {
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 15px;
}

.fileLink {
  gap: 5px;
}

.images {
  gap: 6px;
}

.docs, .images {
  margin-top: 15px;
}

.openImg {
  cursor: pointer;
}

.editImg {
  position: relative;
}

.editImg .deleteFileBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: var(--greyE1);
}

.image {
  width: 147px;
  height: 107px;
  border-radius: 10px;
  border: 1px solid var(--greyE9);
}

.editImg {
  position: relative;
}

.editImg .deleteFileBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: var(--greyE1);
}

.file {
  width: max-content;
  gap: 10px;
}

.fileName {
  margin-left: 10px;
  color: var(--green63);
  font-size: 12px;
}

.deleteFileBtn {
  position: relative;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border-radius: 50%;
  padding: 5px;
  border: 1px solid var(--black05);
  transform: rotate(45deg);
  transition: .3s;
}

.line {
  position: absolute;
  inset: 5px;
  top: calc(50% - 1px);
  display: block;
  height: 1px;
  background-color: var(--black05);
  transition: .3s;
}

.verLine {
  transform: rotate(90deg);
}

.deleteFileBtn:hover {
  border: 1px solid var(--redCF);
}

.deleteFileBtn:hover .line {
  background-color: var(--redCF);
}
.deleteFileBtn:active {
  border: 1px solid var(--black05);
}

.deleteFileBtn:active .line {
  background-color: var(--black05);
}
