.accessWrapper {
  position: relative;
}

.accessPopup {
  position: absolute;
  left: 50%;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid var(--greyF4);
  background-color: var(--white);
  transform: translateX(-50%);
}

.accessPopup li {
  width: max-content;
  cursor: pointer;
}
