.emojiBox {
  position: absolute;
  bottom: calc(100% + 12px);
  right: -60px;
  /* width: max-content; */
  width: 400px;
  height: max-content;
  z-index: 50;
}

.hghg {
  width: 100% !important;
}

em-emoji-picker {
  width: 100% !important;
}

@media (max-width: 1400px) {}

@media (max-width: 1200px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
  .emojiBox {
    width: 300px !important;
  }
}
