.taskHeader {
  gap: 3px;
  width: 100%;
  align-items: center;
  padding-right: 45px;
}

.tags * {
  font-size: 10px;
}

.addTag {
  position: relative;
}
