@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Manrope:wght@200..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@import url(./cssRoots/root.css);

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

body {
  width: 100%;
  height: 100vh;
  background-color: var(--whiteFD);
}

body.modal-show {
  position: relative;
  overflow: hidden;
}

ul, ol {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* div[data-rbd-placeholder-context-id] {
  background-color: red !important;
} */

.container {
  max-width: 1430px;
  padding: 0 15px;
  margin: 0 auto;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: var(--black111);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  user-select: none;
}

#root {
  height: 100%;
  width: 100%;
}

.shadow {
  box-shadow: 0px 4px 11px 0px var(--shadow);
}

.pageShadow {
  box-shadow: inset 0px 4px 11px 0px var(--shadow);
}

.danger {
  color: var(--redCF);
}

.absolutePortal {
  position: absolute;
}


.App {
  height: 100%;
  width: 100%;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.pageContent {
  width: 100%;
  height: calc(100% - 60px);
  padding: 20px;
  border-top-left-radius: 20px;
  background-color: var(--greyF4);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

.pageHeader {
  justify-content: space-between;
  min-height: 60px;
  padding: 0 20px;
}

.contentBlock {
  width: 100%;
  height: max-content;
  padding: 20px 30px;
  border-radius: 10px;
  background-color: var(--whiteFD);
}

.blockTitleWrapper {
  width: 100%;
  padding: 15px;
  border-bottom: 1px solid var(--greyF4);
}

.blockHeader {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--greyF4);
}

.blockTitle {
  font-size: 16px;
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.icon {
  display: flex;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid var(--blue3E5);
  border-radius: 5px;
  background-color: var(--blue3E5);
  color: var(--whiteF8);
  font-size: 16px;
  font-weight: 400;
  transition: .3s;
  cursor: pointer;
}

.btn:hover {
  background-color: var(--blue495);
  border-color: var(--blue495);
}

.btn:active {
  background-color: var(--blue3E5);
  border-color: var(--blue3E5);
}

.btnTransparent {
  padding: 10px 20px;
  color: var(--blue3E5);
  border: 1px solid var(--blue3E5);
  background-color: transparent;
}

.btnTransparent:hover {
  color: var(--blue495);
  border: 1px solid var(--blue495);
  background-color: transparent;
}

.btnTransparent:active {
  color: var(--blue3E5);
  border: 1px solid var(--blue3E5);
  background-color: transparent;
}

.btn:disabled {
  pointer-events: none;
  background-color: var(--grey78);
  border: 1px solid var(--grey78);
}

.btn.progress {
  pointer-events: none;
  background-color: var(--grey78);
}

.iconBtn {
  background: transparent;
  border: unset;
  gap: 10px;
}

.iconBtnGroup {
  gap: 15px;
}

.activeFilter svg path {
  fill: green;
}

.dotsBtn {
  padding: 0 5px;
}

.menuBtn {
  white-space: nowrap;
}

.absoluteLink {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.tableRow {
  position: relative;
  display: table-row;
}

.tableRowHov:hover {
  background-color: var(--blueECF);
}

.tableCell {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 15px 5px;
}

.emptyRow {
  position: absolute;
  left: 0;

}

.tableCell:first-child {
  border-radius: 5px 0 0 5px;
}

.tableCell:last-child {
  border-radius: 0 5px 5px 0;
}

.leftCell {
  text-align: left;
}

.rightCell {
  text-align: right;
}

.tableHeader {
  display: table-header-group;
  border-bottom: 1px solid var(--greyF4);
}

.tableBody {
  display: table-row-group;
}

.imageBox {
  overflow: hidden;
}

.imageBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nonTouch {
  pointer-events: none;
}

/* FORM ELEMENTS */

.inputWrapper {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  height: 38px;
  padding-left: 12px;
  border: 1px solid var(--greyD6);
  border-radius: 5px;
  background: transparent;
}

.inputError {
  border: 1px solid var(--redCF);
}

input:focus {
  outline: none;
}

textarea {
  resize: none;
  word-break: break-word;
}

textarea:focus {
  outline: none;
}

select:focus {
  outline: none;
}

.areaWrapper {
  position: relative;
  height: max-content;
}

.resizeArea {
  position: absolute;
  inset: 0;
  height: 100%;
  padding-top: 5px;
}

.rewidthArea {
  position: relative;
  color: transparent;
  width: max-content;
  min-width: 30px;
}

.rewidthInput {
  position: absolute;
  top: -2px;
  left: -2px;
  padding: 0;
  width: 100%;
}

.hiddenText {
  padding: 4px;
  visibility: hidden;
  white-space: pre-wrap;
  word-break: break-word;
  max-width: 100%;
  width: 100%;
}

.fileInputWrapper {
  position: relative;
  width: max-content;
  height: max-content;
}

.fileInput {
  position: absolute;
  visibility: hidden;
  inset: 0;
}

.fileLabel {
  cursor: pointer;
  margin-right: 15px;
}

.fileLabel span {
  margin-right: 10px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  padding: 20px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(62, 81, 150, .3);
  z-index: 1000;
  overflow: hidden;
}

.popupWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.popupWindow {
  position: relative;
  margin: auto;
  align-self: center;
  background-color: var(--white);
  border-radius: 10px;
  padding: 20px;
  max-width: max-content;
  min-width: 510px;
  height: max-content;
}

.closeBtn {
  position: absolute;
  top: -30px;
  right: -30px;
}

.popupHeader {
  margin-bottom: 16px;
  padding: 0 0 22px 10px;
  font-size: 16px;
  font-weight: 500;
}

.popupform {
  flex-direction: column;
  align-items: flex-start;
}

.checkboxContainer {
  padding-left: 25px;
  justify-content: flex-start;
}

.checkboxContainer:not(:last-child) {
  margin-bottom: 14px;
}

.checkboxContainer input {
  position: absolute;
  margin-right: 14px;
  visibility: hidden;
}

.checkboxContainer label {
  cursor: pointer;
  position: relative;
}

label {
  user-select: none;
}

.checkboxContainer label::before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  border: 1px solid var(--greyD6);
  border-radius: 3px;
  left: -27px;
  box-sizing: border-box;
}

.checkboxContainer input:checked+label::before {
  content: url("./assets/images/icons/checked.svg");
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupInputGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 14px;
}

.popupInputAllView {
  width: 100%;
}

.popupInputBox {
  position: relative;
  justify-content: flex-start;
  margin-bottom: 18px;
  width: 100%;
  gap: 10px;
}

.addBtnWrapper {
  justify-content: flex-end;
}

.popupTextarea {
  padding-top: 12px;
  resize: none;
  height: 70px;
}

.errorMessage {
  position: absolute;
  bottom: -13px;
  left: 10px;
  color: var(--redCF);
  font-size: 10px;
}

.popupBtnsWrapper {
  align-self: flex-end;
}

.popupBtnsWrapper .btnTransparent {
  margin-right: 20px;
}

.popupLabel {
  width: 31%;
  min-width: 31%;
  font-size: 12px;
}

.topLabel {
  align-self: flex-start;
}

.filter .topLabel {
  padding-top: 0;
}

.errorForm {
  color: var(--redCF);
  margin-top: 10px;
  max-width: 100%;
}

.nowrapString {
  white-space: nowrap;
}

.tagsWrapper {
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5px;
  width: max-content;
  width: 100%;
}

.tag {
  padding: 2px 4px;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 500;
}

.tagWhite {
  color: var(--white);
}

/* REACT SELECT */

.multiselectWrapper {
  position: relative;
}

.textSelect {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.react-select__clear-indicator {
  padding: 0 !important;
  width: 14px !important;
  height: 14px !important;
}

.react-select__dropdown-indicator {
  padding: 0 !important;
  width: 14px !important;
  height: 14px !important;
}

.react-select__clear-indicator svg, .react-select__dropdown-indicator svg {
  width: 100% !important;
  height: 100% !important;
}

.react-select__indicator-separator {
  display: none !important;
}

.react-select__multi-value {
  display: none !important;
}

.react-select__control {
  border-color: var(--greyD6);
}

.react-select-container.selectError .react-select__control {
  border-color: var(--redCF);
}

/* ScrollBar */
div ::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

div ::-webkit-scrollbar-track-piece {
  background-color: var(--greyD6);
  border-radius: 1px;
}

div ::-webkit-scrollbar-thumb:vertical {
  background-color: var(--blue3E5);
  border-radius: 1px;
}

div ::-webkit-scrollbar-thumb:horizontal {
  background-color: var(--blue3E5);
  border-radius: 1px;
}

div {
  scrollbar-color: var(--blue3E5) var(--greyD6);
  /* «цвет ползунка» «цвет полосы скроллбара» */
  scrollbar-width: thin;
  /* толщина */
}

.simplebar-scrollbar::before {
  background: var(--blue3E5) !important;
}

.simplebar-scrollbar:hover .simplebar-scrollbar::before {
  background: var(--blue3E5) !important;
  opacity: 1;
}

/* REACT CALENDAR */

.calendar {
  width: 100%;
  border: unset;
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 40px;
}

.react-calendar__navigation__arrow {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: unset;
}

.react-calendar__navigation__prev-button, .react-calendar__navigation__next-button {
  position: relative;
  background: none;
  color: transparent;
}

.react-calendar__navigation__prev-button::before, .react-calendar__navigation__next-button::before {
  content: '';
  position: absolute;
  inset: 0;
  background: url("./assets/images/icons/calendarArrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.react-calendar__navigation__next-button::before {
  transform: scale(-1, 1);
}

.react-calendar__navigation__label {
  width: max-content;
  flex-grow: 0 !important;
  border: unset;
  background: none;
}

.react-calendar__navigation__label__labelText {
  font-size: 20px;
  font-weight: 500;
  color: var(--black111);
}

.react-calendar__navigation__label::first-letter {
  text-transform: uppercase !important;
}

.react-calendar__month-view__weekdays {
  margin-bottom: 12px;
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
}

.react-calendar__month-view__weekdays__weekday abbr {
  font-size: 19px;
  text-decoration: none !important;
}

abbr[title="суббота"], abbr[title="воскресенье"] {
  color: var(--redCA5);
}

.react-calendar__tile {
  position: relative;
  border: unset !important;
  background: none !important;
  padding: 12px 0 12px;
}

.react-calendar__tile abbr {
  font-size: 22px;
  font-weight: 500;
  color: var(--blue3E5);
}

.react-calendar__month-view__days__day--weekend abbr {
  color: var(--black111);
}

.react-calendar__month-view__days__day--neighboringMonth abbr {
  color: var(--grey605);
}

.react-calendar__tile--now abbr {
  color: var(--green3DB);
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__tile--active::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 8px;
  height: 8px;
  background-color: green;
  border-radius: 50%;
}

/* FULL CALENDAR */
.fc {
  width: 100%;
}

.fc-theme-standard .fc-scrollgrid, .fc-theme-standard th, .fc-theme-standard td {
  border: unset !important;
}

.fc-scroller {
  overflow: visible !important;
}

.fc-daygrid-day-events {
  position: absolute !important;
  top: 0 !important;
  left: 50% !important;
  width: 6px !important;
  height: 6px !important;
  transform: translateX(-50%);
}

.fc-daygrid-event-harness {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 6px !important;
  height: 6px !important;
  border-radius: 50% !important;
  overflow: hidden;
}

.fc-daygrid-event-harness * {
  width: 100% !important;
  height: 100% !important;
}

.fc-event {
  margin: 0 !important;
}

.fc-event-title {
  visibility: hidden;
}

.fc-daygrid-day-top {
  position: absolute;
  inset: 0;
  justify-content: center;
  align-items: center;
}

.fc-daygrid-day-top a {
  font-size: 16px;
}

.fc-day-today {
  background-color: transparent !important;
}

.fc-day-today .fc-daygrid-day-frame {
  background-color: var(--blueECF);
  border-radius: 20px;
}

.fc-day-today .fc-daygrid-day-frame .fc-daygrid-day-number {
  font-weight: 700;
}

.fc-header-toolbar {
  position: relative;
  padding-top: 80px;
}

.fc-today-button {
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--blue3E5) !important;
  border-color: var(--blue3E5) !important;
}

.fc-toolbar-title {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 18px !important;
}

.fc-toolbar-title::first-letter {
  text-transform: uppercase;
}

.fc-button-group {
  margin-left: 0 !important;
  position: absolute !important;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.fc-prev-button, .fc-next-button {
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  border-radius: 50% !important;
  flex: none !important;
  background-color: transparent!important;
  border: unset !important;
  text-align: center;
  background: url("./assets/images/icons/calendarArrow.svg") !important;
  background-size: contain !important;
}

.fc-next-button {
  transform: rotate(180deg);
}

.fc-prev-button span, .fc-next-button span {
  display: none;
}

/* DatePicker */
.react-datepicker-wrapper {
  position: relative;
  width: 100%;
}

.react-datepicker-wrapper svg {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}

.pickerDate {
  width: 100%;
  height: 38px;
  padding-left: 12px;
  border: 1px solid var(--greyD6);
  border-radius: 5px;
  background: transparent;
}

.pickerError {
  border: 1px solid var(--redCF);
}

.react-datepicker {
  border: unset !important;
  background-color: var(--white) !important;
  color: var(--black111) !important;
  box-shadow: 0px 4px 11px 0px var(--shadow);
  border-radius: 8px !important;
}

.react-datepicker__navigation {
  width: 16px !important;
  height: 16px !important;
}

.react-datepicker__navigation {
  background: url("./assets/images/icons/calendarArrow.svg") !important;
  background-size: contain !important;
  top: 8px !important;
}

.react-datepicker__navigation--previous {
  left: 25px !important;
}

.react-datepicker__navigation--next {
  right: 25px !important;
  transform: scale(-1, 1);
}

.react-datepicker__navigation span {
  display: none;
}

.react-datepicker__day-name[aria-label="суббота"],
.react-datepicker__day-name[aria-label="воскресенье"] {
  color: var(--redCA5);
}

.react-datepicker__header {
  background-color: unset !important;
  border-bottom: unset !important;
  padding: 8px 0 0 0 !important;
}

.react-datepicker__current-month::first-letter {
  text-transform: uppercase;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day--outside-month {
  color: var(--grey605) !important;
}

.react-datepicker__day--in-range {
  background-color: unset !important;
  color: var(--blue3E5) !important;
}

.react-datepicker__day--range-start, .react-datepicker__day--range-end {
  background-color: var(--blue3E5) !important;
  color: var(--white) !important;
}

/* quill */
.ql-toolbar.ql-snow {
  display: none;
  width: 100%;
  border: 1px solid var(--greyD6) !important;
  border-radius: 5px;
  background-color: var(--white);
}

.addQuillWrapper .ql-toolbar.ql-snow {
  position: absolute;
  top: 0;
  transform: translateY(calc(-100% - 3px));
  z-index: 40;
}

.editQuillWrapper {
  padding: 0;
}

.quillWrapper.active .ql-toolbar.ql-snow {
  display: block;
}

.addQuillWrapper .ql-editor, .editQuillWrapper .ql-editor {
  background-color: var(--white);
  border: 1px solid var(--greyD6);
  border-radius: 5px;
  padding: 10px 105px 10px 15px !important;
}

.editQuillWrapper .ql-editor {
  padding: 10px 15px 10px 15px !important;
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
}

.ql-container.ql-snow {
  border: unset !important;
}

.messageCopy {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 340px;
  height: max-content;
  padding: 20px 10px;
  border-radius: 10px;
  border: 1px solid var(--white);
  background-color: var(--blue3E5);
  text-align: center;
  color: var(--white);
  z-index: 10000;
}

.messageCopy.error {
  background-color: var(--redCF);
}

@media (max-width: 1400px) {}

@media (max-width: 1200px) {}

@media (max-width: 992px) {
  .popup {
    padding: 15px;
  }
}

@media (max-width: 768px) {
  * {
    font-size: 12px;
  }

  .popupLabel {
    min-width: auto;
    width: max-content;
  }

  .popupInputBox {
    flex-direction: column;
    align-items: flex-start;
  }

  .popupInputBox .popupLabel~.popupErrorMessage {
    left: 10px;
  }

  .contentBlock {
    padding: 20px 20px;
  }

  .pageContent {
    padding: 10px;
  }

  .popupWindow {
    min-width: 100%;
  }

  .popupInputBox {
    width: 100%;
  }

  .closeBtn {
    top: 15px;
    right: 15px;
  }

  .closeBtn svg path {
    stroke: var(--blue3E5);
  }
}

@media (max-width: 576px) {
  .popupWindow {
    padding: 15px;
  }
}

@media (max-width: 350px) {
  .popupBtnsWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-self: flex-end;
  }

  .popupBtnsWrapper .btnTransparent {
    margin-right: 0;
  }
}
