.banner {
  position: relative;
  justify-content: center;
  min-width: 100%;
  height: 222px;
  border-radius: 5px;
  background-color: grey;
}

.editBtns {
  position: absolute;
  top: 10px;
  left: 10px;
}

.file {
  width: max-content;
  height: max-content;
}

.fileLabel {
  margin: 0;
}
