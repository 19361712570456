.commentsHeader {
  margin-bottom: 16px;
}

.commentItem:not(:last-child) {
  border-bottom: 1px solid var(--greyF4);
}

.commentEmpty {
  margin-top: 15px;
}

.title {
  padding-left: 20px;
  font-size: 14px;
  font-weight: 700;
}


