.blockTitle {
  margin-bottom: 14px;
}

.usersList {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 20px;
}

.fileBtnGroup {
  justify-content: space-between;
  width: 100%;
}

.linkIcon {
  margin-right: 10px;
}

.accessContainer {
  margin-bottom: 25px;
}

.accessWrapper {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.accessSelect {
  border: unset;
  padding: 0;
}

.accessDescr {
  padding-left: 5px;
  font-size: 10px;
  font-weight: 400;
}

.accessIcon {
  margin-right: 10px;
}
