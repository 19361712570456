.imgPopup {
  padding: 15px;
}

.imageInPopup {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.imageInPopup img {
  object-fit: contain;
}

.imageInPopup.width img {
  width: 100%;
  height: auto;
}
.imageInPopup.height img {
  width: auto;
  height: 100%;
}
.imageInPopup.oneToOne img {
  width: 100%;
  height: 100%;
}


.deleteFileBtn {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border-radius: 50%;
  padding: 5px;
  border: 1px solid var(--black05);
  transform: rotate(45deg);
  transition: .3s;
}

.line {
  position: absolute;
  inset: 5px;
  top: calc(50% - 1px);
  display: block;
  height: 1px;
  background-color: var(--black05);
  transition: .3s;
}

.verLine {
  transform: rotate(90deg);
}

.deleteFileBtn:hover {
  border: 1px solid var(--redCF);
}

.deleteFileBtn:hover .line {
  background-color: var(--redCF);
}

.deleteFileBtn:active {
  border: 1px solid var(--black05);
}

.deleteFileBtn:active .line {
  background-color: var(--black05);
}
