.taskUsers {
  width: 100%;
}

.userTitleWrap {
  margin-bottom: 12px;
}

.users {
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 15px;
}

.taskUserWrapper {
  position: relative;
}

.btnWrap {
  position: absolute;
  left: 0;
  top: 0;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--grey78);
}
