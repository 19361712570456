.full {
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  padding: 5px;
}

.description {
  /* Text rows count */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.path {
  color: var(--grey39);
  font-size: 10px;
  font-weight: 400;
}

.controls {
  width: 100%;
  justify-content: space-between;
}
