.usersWrap {
  max-width: 385px;
}

.users {
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 30px;
}
