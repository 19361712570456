.content {
  width: 100%;
  height: 100%;
  padding: 0;
  overflow-x: auto;
}

.maxHeight {
  max-height: 100vh;
}

@media (max-width: 1400px) {

}

@media (max-width: 1300px) {

}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {}

@media (max-width: 576px) {

}
