.colorsWrapper {
  justify-content: flex-start;
  gap: 6px;
  flex-wrap: wrap;
  margin-right: 10px;
  max-width: 100%;
}

.withoutColorBtn {
  height: 23px;
  padding: 0 10px;
  font-size: 12px;
}

.colorInputBox {
  position: relative;
  min-width: 23px;
  height: 23px;
}

.colorInput {
  visibility: hidden;
  position: absolute;
}

.colorLabel {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  cursor: pointer;
}

.colorLabel::after {
  content: '';
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.colorInputBox .colorInput:checked+.colorLabel::after {
  content: url("../../../../../../../../assets/images/icons/checked.svg");
}

.createNewWrap {
  width: 100%;
  margin-bottom: 20px;
}

.createNewColor {
  width: 100%;
}

.colorAddBtn {
  position: absolute;
  visibility: hidden;
}

.colorInputWrapper {
  width: max-content;
  height: max-content;
}

.colorFromInput {
  border: 1px solid var(--blue3E5);
  border-radius: 4px;
}

.editBtns {
  margin-bottom: 20px;
  width: 100%;
  gap: 20px;
}

.editBtns button {
  width: 100%;
  margin-right: 0 !important;
}
