.columnHeader {
  position: relative;
  padding: 20px;
}

.columnTitle {
  font-size: 16px;
}

.addTaskForm {
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  width: 100%;
  padding: 10px;
  margin-top: 12px;
}

.textWrapper {
  width: 100%;
  min-height: 60px;
}

.smallbtn {
  font-size: 10px;
  padding: 8px 20px;
}

.columnState {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  padding: 1px 3px 3px 3px;
  border-radius: 4px;
  top: 2px;
  right: 22px;
  z-index: 20;
}

.columnState span {
  font-size: 12px;
  cursor: pointer;
}

.stateControls {
  top: 100%;
  right: 0;
  padding-top: 5px;
}

.renameWrap {
  position: relative;
  height: max-content;
  width: 100%;
  padding-right: 10px;
}

.renameCol {
  border: unset;
  border-bottom: 1px solid var(--blue3E5);
  background-color: transparent;
}
