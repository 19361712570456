.descrWrap {
  position: relative;
  width: 100%;
  min-height: 30px;
}

.taskDescr {
  cursor: pointer;
}

.taskDescr img {
  max-width: 70%;
  height: auto;
  display: block;
}

.edit {
  width: 100%;
  max-width: 100%;
  gap: 5px;
}

@media (max-width: 576px) {
  .taskDescr img {
    max-width: 100%;
  }
}
