.controls {
  position: relative;
  margin-bottom: 24px;
}

.userBtn {
  width: max-content;
  border: unset;
  background-color: transparent;
  cursor: pointer;
}
