.openImg {
  cursor: pointer;
}

.editImg {
  position: relative;
}

.editImg .deleteFileBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: var(--greyE1);
}

.image {
  position: relative;
  width: 147px;
  height: 107px;
  border-radius: 10px;
  border: 1px solid var(--greyE9);
  overflow: visible;
}

.image img {
  border-radius: 10px;
}

.image

.editImg {
  position: relative;
}

.editImg .deleteFileBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: var(--greyE1);
}

.btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
