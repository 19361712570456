.sidebar {
  /* position: relative; */
  position: fixed;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  width: max-content;
  padding: 20px 34px;
  z-index: 150;
}

.sidebar.fullNav {
  align-items: flex-start;
}

.version {
  position: absolute;
  bottom: 5px;
  left: 15px;
}

@media (max-width: 1400px) {}

@media (max-width: 1300px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {
  .sidebar {
    width: 30px;
    padding: 20px 0;
  }

  .sidebar.fullNav {
    padding: 20px 35px 20px 15px;
    width: max-content;
  }
}

@media (max-width: 576px) {
  .sidebar.fullNav {
    background-color: var(--white);
    z-index: 100;
  }
}
