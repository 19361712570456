.chatBtn {
  position: fixed;
  bottom: 40px;
  right: 20px;
  height: 50px;
  width: 50px;
  border: unset;
  background: transparent;
  z-index: 950;
}

.chatBtn img {
  height: 100%;
  width: auto;
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
  .chatBtn {
    height: 36px;
    width: 36px;
  }
}
