.statusList {
  flex-direction: column;
  gap: 15px;
  position: absolute;
  right: -10px;
  top: 0;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--white);
  transform: translateX(100%);
  z-index: 5;
}

.bottomRight {
  right: 0;
  top: calc(100% - 10px);
  transform: translateX(0);
}

.bottomLeft {
  right: auto;
  left: 0;
  top: calc(100% - 10px);
  transform: translateX(0);
}

.commentAccess {
  top: 100%;
  left: 50%;
  align-items: flex-start;
  gap: 10px;
  width: max-content;
  height: max-content;
  transform: translateX(-50%);
}

.commentAccess .statusItem {
  position: relative;
  padding-left: 15px;
  text-align: start;
}

.commentAccess .statusItem span {
  position: absolute;
  left: 0;
}

.statusItem {
  width: 100%;
  transition: .3s;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

.statusItem:hover {
  color: var(--blue3E5);
}

.forColumn {
  right: 0;
  top: 100%;
  transform: none;
}

.delItem {
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid var(--greyE7);
  padding-top: 8px;
}
