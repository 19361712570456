.metaWrap {
  gap: 20px;
}

.metaField {
  gap: 10px;
  width: max-content;
}

.metaHeader {
  color: var(--grey605);
}

.colName {
  font-weight: 500;
  position: relative;
}

.pickWrapper {
  width: 110px;
}

.pickWrapper input {
  height: 25px;
}

.pointer {
  cursor: pointer;
}
