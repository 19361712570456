.formWrap {
  position: relative;
  z-index: 55;
}

.sendBtn {
  margin-left: 20px;
  margin-top: 10px;
  align-self: flex-start;
}

.access {
  position: absolute;
  right: 10px;
  top: 8px;
  gap: 5px;
}

.commentInput {
  padding-right: 80px;
  padding-top: 9px;
  word-break: break-all;
}

.commentHidden {
  min-height: 40px;
  padding-right: 80px;
  padding-top: 9px;
  padding-left: 13px;
  visibility: hidden;
  word-break: break-all;
}


