.archivedList {
  flex-direction: column;
  width: 300px;
  padding: 5px 15px;
  border-radius: 10px;
  background-color: var(--white);
  z-index: 5;
}


