.welcome {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  background-image: url('../../../../assets/images/LoginBack.jpg');
  background-size: cover;
  background-position: top center;
  z-index: 1100;
  overflow-y: auto;
}

.welcome p,
.welcome h1,
.welcome h2,
.welcome span {
  color: var(--white);
}

.welcome h1 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 20px;
}

.welcome h2 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 50px;
}

.formPreview {
  margin-bottom: 50px;
  text-align: center;
}

.red {
  color: var(--redCF) !important;
}

.welcomeForm {
  background-color: rgba(120, 120, 120, .7);
  border-radius: 20px;
  padding: 20px;
}

.welcomeInput {
  background-color: var(--white);
}

.formFields {
  width: 100%;
  display: grid;
  grid-template-columns: calc(30% - 20px) calc(70% - 20px);
  gap: 40px;
}

.formPart {
  width: 100%;
  height: 100%;
}

.avatarBox {
  position: relative;
  justify-content: center;
  border-radius: 10px;
  border: 2px dashed var(--blue3E5);
  overflow: hidden;
}

.fileInput {
  position: absolute;
  inset: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 10;
}


.avatarPreview{
  position: absolute;
  inset: 0;
  z-index: 5;
}

@media (max-width: 768px) {
  .formFields {
    width: 100%;
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    gap: 20px;
  }
}

@media (max-width: 576px) {
  .formFields {
    grid-template-columns: 100%;
  }

  .avatarBox {
    height: 200px;
  }
}
