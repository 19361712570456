.linkWrapper {
  position: relative;
  height: 100%;
  width: max-content;
}

.linkWrapper:not(:last-child) {
  margin-right: 5px;
}

.board {
  position: relative;
  display: block;
  padding: 10px 53px 10px 45px;
  border-radius: 0;
  background-color: transparent;
  border-right: 1px solid var(--greyE6);
  min-height: 0;
  height: max-content;
  transition: .3s;
}

.radius {
  position: absolute;
  bottom: 0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: var(--greyF4);
  transition: .3s;
  z-index: -1;
}

.radius div {
  position: absolute;
  inset: 0;
  border-radius: 10px;
  background-color: var(--whiteFD);
  z-index: -1;
}

.radiusLeft {
  left: 0;
  transform-origin: left;
}

.radiusRight {
  right: 0;
  transform-origin: right;
}

.board:hover {
  color: var(--blue3E5);
}

.board.active {
  min-height: 100%;
  border-radius: 10px 10px 0 0;
  background-color: var(--greyF4);
  border-right: 1px solid var(--greyF4);
}

.board.active .radiusLeft {
  transform: scale(-1, 1);
  border-radius: 10px 10px 10px 0;
}

.board.active .radiusRight {
  transform: scale(-1, 1);
  border-radius: 10px 10px 0 10px;
}

.boardBtn {
  position: absolute;
  right: 15px;
  top: 9px;
  padding: 5px;
}

.backRename {
  position: relative;
  color: transparent;
}

.renameBoard {
  position: absolute;
  left: -5px;
  right: -5px;
  padding: 0;
  border: unset;
  text-align: center;
  border-bottom: 1px solid var(--blue3E5);
}

