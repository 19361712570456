.invite {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  background-image: url('../../../../assets/images/LoginBack.jpg');
  background-size: cover;
  background-position: top center;
  z-index: 1100;
  overflow-y: auto;
}

.invite h1,
.invite h2 {
  color: var(--white);
}

.invite h1 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 20px;
}

.invite h2 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 50px;
}

.error {
  color: var(--redCF);
  text-align: center;
  font-size: 16px;
}
