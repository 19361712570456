.framePopup {
  height: max-content;
  border-radius: 50px;
  min-width: 700px;
  padding: 20px;
}

.frame {
  width: 100% !important;
  height: 640px;
}

.clsBtn {
  position: absolute;
  right: 40px;
  top: 40px;
}

@media (max-width: 992px) {
  .framePopup {
    height: max-content;
    max-height: max-content;
  }
}


@media (max-width: 768px) {
  .framePopup {
    min-width: 100%;
  }
}

@media (max-width: 551px) {
  .framePopup {
    padding: 10px;
    border-radius: 40px;
    overflow: hidden;
  }

  .frame {
    height: 640px;
  }

  .clsBtn {
    right: 30px;
    top: 30px;
  }
}

@media (max-width: 531px) {
  .frame {
    height: 660px;
  }
}

@media (max-width: 372px) {
  .frame {
    height: 680px;
    overflow-x: hidden !important;
  }

  .frame * {
    overflow: hidden !important;
  }

}
