.archivedColsWrapper {
  padding-top: 20px;
}

.archivedColsList {
  flex-direction: column;
  gap: 12px;
  min-width: 300px;
  width: 344px;
}

.arhiveBtn {
  margin-bottom: 36px;
}

@media (max-width: 1400px) {}

@media (max-width: 1200px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {

  .archivedColsList {
    gap: 8px;
    width: 310px;
  }
}

@media (max-width: 576px) {}
