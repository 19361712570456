.comment {
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 0 38px 0;
}



.editCommentContainer {
  z-index: 60;
}

.author {
  width: max-content;
}

.commentContent {
  width: 100%;
}

.authorName {
  padding-left: 10px;
  font-weight: 600;
}

.authorName.mobile {
  display: none;
}

.commentTextWrapper {
  width: 100%;
}

.commentText {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
}

.commentText p {
  max-width: 100%;
  width: 100%;
  overflow: hidden;
}

.commentText img {
  max-width: 50%;
  height: auto;
  display: block;
}

.commentMeta {
  position: absolute;
  right: 0;
  bottom: 10px;
}

.commentBtns {
  position: absolute;
  gap: 10px;
  left: 0;
  bottom: 5px;
}

.commentMeta * {
  font-size: 10px;
  font-weight: 400;
}

.date {
  margin-right: 10px;
}

.isPin {
  position: absolute;
  right: 0;
  top: 17px;
}

.areaWrapper {
  position: relative;
  max-width: 100%;
  min-height: 38px;
}

.backText {
  visibility: hidden;
  padding-right: 10px;
}

.editComment {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  resize: none;
  padding-top: 3px;
}

.access {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) translateX(-100%);
}

.filesWrapper {
  margin-top: 20px;
}

.files {
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 15px;
}

.fileLink {
  gap: 5px;
}

.images {
  gap: 6px;
}

.docs, .images {
  margin-top: 15px;
}

.openImg {
  cursor: pointer;
}

.image {
  width: 147px;
  height: 107px;
  border-radius: 10px;
  border: 1px solid var(--greyE9);
}

.file {
  width: max-content;
  gap: 10px;
}

.fileName {
  margin-left: 10px;
  color: var(--green63);
  font-size: 12px;
}

.showFilesBtn {
  cursor: pointer;
}

.editControls {
  position: absolute;
  top: 16px;
  right: 0;
  gap: 5px;
}

.smallbtn {
  font-size: 12px;
  padding: 5px 10px;
}


@media (max-width: 1400px) {
  .comment {
    flex-direction: column;
  }

  .commentBtns {
    bottom: 3px;
  }

  .authorName.desktop {
    display: none;
  }
  .authorName.mobile {
    display: block;
  }
}

@media (max-width: 1200px) {
  .comment {
    flex-direction: row;
  }

  .authorName.desktop {
    display: block;
  }
  .authorName.mobile {
    display: none;
  }

  .commentBtns {
    bottom: 5px;
  }
}

@media (max-width: 992px) {}

@media (max-width: 768px) {
  .comment {
    flex-direction: column;
  }

  .authorName.desktop {
    display: none;
  }
  .authorName.mobile {
    display: block;
  }

  .commentBtns {
    bottom: 3px;
  }
}

@media (max-width: 576px) {
  .commentText img {
    max-width: 90%;
  }
}
